// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import Step01 from "components/quickstart/Step01";
import Step02 from "components/quickstart/Step02";
import Step03 from "components/quickstart/Step03";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const QuickStart = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="quick_start">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("QuickStart")}</h3>
              <hr />
              <p>{props.t("QuickStart01")}</p>
              <p>{props.t("QuickStart02")}</p>
              <p>{props.t("QuickStart03")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <Step01 />
            </Col>
            <Col lg="12" className="my-3">
              <Step02 />
            </Col>
            <Col lg="12" className="my-3">
              <Step03 />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(QuickStart);
