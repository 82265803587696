// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

import { CodeBlock, atomOneLight } from "react-code-blocks";

// React Router Dom
import { Link } from "react-router-dom";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Step02 = (props) => {
  // Local States
  const codeExample = `POST /signatures
{
  "signature_name": "sw_luke"
}`;

  // Render
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("QuickStartStep02")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("QuickStartStep0201")}</p>
          <p>{props.t("QuickStartStep0202")}</p>
          <div className="d-flex justify-content-center my-4">
            <div className="border rounded bg-light p-3">
              <CodeBlock
                text={codeExample}
                language="javascript"
                showLineNumbers="false"
                theme={atomOneLight}
                wrapLongLines
                customStyle={{ width: "100%" }}
              />
            </div>
          </div>
          <p>{props.t("QuickStartStep0203")}</p>
          <p>
            {props.t("ForMoreInfoPlural")}
            <Trans i18nKey="QuickStartStep0204">
              <Link to="/http_api/1">http_api-signatures</Link>
              <Link to="/dashboard_signatures">/dashboard_signatures</Link>
              <Link to="/digital_identity">digital_identity</Link>
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Step02);
