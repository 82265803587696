// REACT
import React, { useState, useEffect } from "react";

// REACTSTRAP
import { Card, CardHeader, CardBody } from "reactstrap";

// COMPONENTS
import ImageZoomer from "components/ui/ImageZoomer";

// IMAGES
import CheckerVerifyDataImgES from "assets/images/screenshots/es/checker04.png";
import CheckerVerifyDataImgEN from "assets/images/screenshots/en/checker04.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const CheckerVerifyData = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(CheckerVerifyDataImgES);
    } else {
      setScreenshot(CheckerVerifyDataImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("CheckerVerifyData")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("CheckerVerifyData01")}</p>
          <p>{props.t("CheckerVerifyData02")}</p>
          <ImageZoomer src={screenshot} alt="IBS CheckerVerifyData website." />
          <p>{props.t("CheckerVerifyData03")}</p>
          <p>{props.t("CheckerVerifyData04")}</p>
          <p>{props.t("CheckerVerifyData05")}</p>
          <p>{props.t("CheckerVerifyData06")}</p>
          <p>{props.t("CheckerVerifyData07")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(CheckerVerifyData);
