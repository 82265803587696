// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// React Router Dom
import { Link } from "react-router-dom";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DashboardWebhooksCreationImg01ES from "assets/images/screenshots/es/webhooks.png";
import DashboardWebhooksCreationImg01EN from "assets/images/screenshots/en/registration.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const DashboardWebhooksCreation = (props) => {
  // Local States
  const [screenshot01, setScreenshot01] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot01(DashboardWebhooksCreationImg01ES);
    } else {
      setScreenshot01(DashboardWebhooksCreationImg01EN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DashboardWebhooksCreation")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DashboardWebhooksCreation01")}</p>
          <p>{props.t("DashboardWebhooksCreation02")}</p>
          <ImageZoomer src={screenshot01} alt="" />
          <p>{props.t("DashboardWebhooksCreation03")}</p>
          <p>{props.t("DashboardWebhooksCreation04")}</p>
          <p>{props.t("DashboardWebhooksCreation05")}</p>
          <p>{props.t("DashboardWebhooksCreation06")}</p>
          <p>
            <Trans i18nKey="DashboardWebhooksCreation07">
              <Link to="/webhooks">webhooks</Link>
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DashboardWebhooksCreation);
