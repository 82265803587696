// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import Registration from "components/account/Registration";
import TrialPeriod from "components/account/TrialPeriod";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const Account = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="account">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Account")}</h3>
              <hr />
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <Registration />
            </Col>
            <Col lg="12" className="my-3">
              <TrialPeriod />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Account);
