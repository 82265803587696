// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Row, Container, Col, Card, CardBody } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

const OtherResources = (props) => {
  // Local States
  const swaggerURL = "https://api.icommunitylabs.com/v2/swagger/";
  const redocURL = "https://api.icommunitylabs.com/v2/redoc";
  const tutorialesURL = "https://www.youtube.com/watch?v=y48tPelGTHc&list=PLMmHEHGKiOwPnBdRqfBw2TOJfGZpYE5Yj";
  const [dashboardManualURL, setdashboardManualURL] = useState("");
  const [digitalIdentityManualURL, setDigitalIdentityManualURL] = useState("");

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setdashboardManualURL("https://hackmd.io/@iCommunity/HJsvDgEKh");
      setDigitalIdentityManualURL("https://hackmd.io/@iCommunity/r12JqPrK2");
    } else {
      setdashboardManualURL("https://hackmd.io/@iCommunity/HysbvQHYn");
      setDigitalIdentityManualURL("https://hackmd.io/@iCommunity/SkNBLIFY3");
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="external_documentation">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("OtherResources")}</h3>
              <hr />
              <p>{props.t("OtherResources01")}</p>
            </Col>
          </Row>
          <Row className="align-items-stretch my-1">
            <Col xl="6" sm="12" className="my-3">
              <Card className="border-0 h-100">
                <CardBody style={{ minHeight: "150px" }}>
                  <div className="text-center mb-1">
                    <i className="bx bx-terminal text-dark font-size-20 mt-4"></i>
                  </div>
                  <h4 className="text-center mb-4">Swagger</h4>
                  <div className="mb-2">{props.t("SwaggerIntroduction")}</div>
                  <div className="text-end p-3">
                    <a href={swaggerURL} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                      {props.t("SwaggerButton")}
                      <span>
                        <i className="bx bx-link-external mx-2"></i>
                      </span>
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" sm="12" className="my-3">
              <Card className="border-0 h-100">
                <CardBody style={{ minHeight: "150px" }}>
                  <div className="text-center mb-1">
                    <i className="bx bx-hash text-dark font-size-20 mt-4"></i>
                  </div>
                  <h4 className="text-center mb-4">Redoc</h4>
                  <div className="justify mb-2">{props.t("RedocIntroduction")}</div>
                  <div className="text-end p-3">
                    <a href={redocURL} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                      {props.t("RedocButton")}
                      <span>
                        <i className="bx bx-link-external mx-2"></i>
                      </span>
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" sm="12" className="my-3">
              <Card className="border-0 h-100">
                <CardBody style={{ minHeight: "150px" }}>
                  <div className="text-center mb-1">
                    <i className="bx bxl-youtube text-dark font-size-20 mt-4"></i>
                  </div>
                  <h4 className="text-center mb-4">{props.t("VideoTutorials")}</h4>
                  <div className="justify mb-2">{props.t("VideoTutorialsIntroduction")}</div>
                  <div className="text-end p-3">
                    <a href={tutorialesURL} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                      {props.t("VideoTutorialsButton")}
                      <span>
                        <i className="bx bx-link-external mx-2"></i>
                      </span>
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="6" sm="12" className="my-3">
              <Card className="border-0 h-100">
                <CardBody style={{ minHeight: "150px" }}>
                  <div className="text-center mb-1">
                    <i className="bx bx-bar-chart-square text-dark font-size-20 mt-4"></i>
                  </div>
                  <h4 className="text-center mb-4">{props.t("DashboardManual")}</h4>
                  <div className="justify mb-2">{props.t("DashboardManualIntroduction")}</div>
                  <div className="text-end p-3">
                    <a
                      href={dashboardManualURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      {props.t("DashboardManualButton")}
                      <span>
                        <i className="bx bx-link-external mx-2"></i>
                      </span>
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" sm="12" className="my-3">
              <Card className="border-0 h-100">
                <CardBody style={{ minHeight: "150px" }}>
                  <div className="text-center mb-1">
                    <i className="bx bx-id-card text-dark font-size-20 mt-4"></i>
                  </div>
                  <h4 className="text-center mb-4">{props.t("DigitalIdentityManual")}</h4>
                  <div className="justify mb-2">{props.t("DigitalIdentityManualIntroduction")}</div>
                  <div className="text-end p-3">
                    <a
                      href={digitalIdentityManualURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      {props.t("DigitalIdentityManualButton")}
                      <span>
                        <i className="bx bx-link-external mx-2"></i>
                      </span>
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(OtherResources);
