// REACT
import React, { useState, useEffect } from "react";

// REACTSTRAP
import { Card, CardHeader, CardBody } from "reactstrap";

// COMPONENTS
import ImageZoomer from "components/ui/ImageZoomer";

// IMAGES
import CheckerEnterDataImgES from "assets/images/screenshots/es/checker01.png";
import CheckerEnterDataImgEN from "assets/images/screenshots/en/checker01.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const CheckerEnterData = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(CheckerEnterDataImgES);
    } else {
      setScreenshot(CheckerEnterDataImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("CheckerEnterData")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("CheckerEnterData01")}</p>
          <p>
            <Trans i18nKey={"CheckerEnterData02"}>
              <a href="https://www.avax.network/" target="_blank" rel="noreferrer">
                Avalanche
              </a>
              <a href="https://ethereum.org/" target="_blank" rel="noreferrer">
                Ethereum
              </a>
              <a href="https://fantom.foundation/" target="_blank" rel="noreferrer">
                Fantom
              </a>
              <a href="https://www.gnosis.io/" target="_blank" rel="noreferrer">
                Gnosis
              </a>
              <a href="https://polygon.technology/" target="_blank" rel="noreferrer">
                Polygon
              </a>
            </Trans>
          </p>
          <ImageZoomer src={screenshot} alt="IBS CheckerEnterData website." />
          <p>{props.t("CheckerEnterData03")}</p>
          <p>
            <Trans i18nKey={"CheckerEnterData04"}>
              <a href="https://dashboard.icommunitylabs.com" target="_blank" rel="noreferrer">
                Dashboard
              </a>
            </Trans>
          </p>
          <p>{props.t("CheckerEnterData05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(CheckerEnterData);
