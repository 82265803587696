// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DashboardWebhooksCreationImg01ES from "assets/images/screenshots/es/signRequestES.png";
import DashboardWebhooksCreationImg01EN from "assets/images/screenshots/en/signRequestEN.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DashboardRequestSig = (props) => {
    // Local States
    const [screenshot01, setScreenshot01] = useState(null);

    // UseEffects
    useEffect(() => {
        if (props.i18n.language === "es") {
            setScreenshot01(DashboardWebhooksCreationImg01ES);
        } else {
            setScreenshot01(DashboardWebhooksCreationImg01EN);
        }
    }, [props.i18n.language]);

    // RENDER
    return (
        <React.Fragment>
            <Card className="border-0" id="new_evidence">
                <CardHeader className="border-0 bg-white py-3">
                    <h5>{props.t("DashboardRequestSig")}</h5>
                </CardHeader>
                <CardBody>
                    <p>{props.t("DashboardRequestSig01")}</p>
                    <ImageZoomer src={screenshot01} alt="" />
                    <p>{props.t("DashboardRequestSig02")}</p>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(DashboardRequestSig);
