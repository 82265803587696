import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

// i18n
import { withTranslation, Trans } from "react-i18next";

const ConceptIpfs = (props) => {
  return (
    <React.Fragment>
      <Card className="border-0" id="c_ipfs">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("IPFS")}</h5>
        </CardHeader>
        <CardBody>
          <p>
            <Trans i18nKey="IPFS01">
              <a href="https://ipfs.tech/" target="_blank" rel="noreferrer">
                IPFS
              </a>
              es un acrónimo para Sistema de Archivos Interplanetario
              (InterPlanetary File System), un protocolo y una red para guardar
              y compartir archivos p2p (peer-to-peer) en un sistema de archivos
              distribuido.
            </Trans>
          </p>
          <p>{props.t("IPFS02")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptIpfs);
