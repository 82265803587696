// REACT
import React, { useState, useEffect } from "react";

// REACTSTRAP
import { Card, CardHeader, CardBody } from "reactstrap";

// COMPONENTS
import ImageZoomer from "components/ui/ImageZoomer";

// IMAGES
import RequestsDocImgES from "assets/images/screenshots/es/UploadDocumentsES.png";
import RequestsDocImgEN from "assets/images/screenshots/en/UploadDocumentsEN.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const RequestsDoc = (props) => {
    // Local States
    const [screenshot01, setScreenshot01] = useState(null);

    // UseEffects
    useEffect(() => {
        if (props.i18n.language === "es") {
            setScreenshot01(RequestsDocImgES);
        } else {
            setScreenshot01(RequestsDocImgEN);
        }
    }, [props.i18n.language]);

    // RENDER
    return (
        <React.Fragment>
            <Card className="border-0" id="new_evidence">
                <CardHeader className="border-0 bg-white py-3">
                    <h5>{props.t("RequestsDoc")}</h5>
                </CardHeader>
                <CardBody>
                    <p>{props.t("RequestsDoc01")}</p>
                    <ImageZoomer
                        className="img-fluid img-center img-700"
                        src={screenshot01}
                        alt="IBS CheckerCheckDataError website."
                    />
                    <p>{props.t("RequestsDoc02")}</p>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(RequestsDoc);
