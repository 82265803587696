// React
import React, {useState, useEffect} from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// React Router Dom
import { Link } from "react-router-dom";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DashboardEvidencesCreationImg01ES from "assets/images/screenshots/es/evidences.png";
import DashboardEvidencesCreationImg01EN from "assets/images/screenshots/en/registration.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const DashboardEvidencesCreation = (props) => {
  // Local States
  const [screenshot01, setScreenshot01] = useState(null);

  // UseEffects
  useEffect(() => {
      if (props.i18n.language === "es") {
        setScreenshot01(DashboardEvidencesCreationImg01ES);
      } else {
        setScreenshot01(DashboardEvidencesCreationImg01EN);
      }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DashboardEvidencesCreation")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DashboardEvidencesCreation01")}</p>
          <p>{props.t("DashboardEvidencesCreation02")}</p>
          <p>{props.t("DashboardEvidencesCreation03")}</p>
            <ImageZoomer
              src={screenshot01}
              alt=""
            />
          <p>{props.t("DashboardEvidencesCreation04")}</p>
          <p>{props.t("DashboardEvidencesCreation05")}</p>
          <p>{props.t("DashboardEvidencesCreation06")}</p>
          <p>{props.t("DashboardEvidencesCreation07")}</p>
          <p>{props.t("DashboardEvidencesCreation08")}</p>
          <p>
            <Trans i18nKey="DashboardEvidencesCreation09">
              <Link to="/dashboard_webhooks">dashboard_webhooks</Link>
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DashboardEvidencesCreation);
