import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createBrowserHistory } from "history";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let history = createBrowserHistory();
    return <Component {...props} router={{ location, navigate, params }} history={history} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
