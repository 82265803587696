// React
import React, { useState } from "react";

// Reactstrap
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";

// React Code Blocks
import { CopyBlock, atomOneLight } from "react-code-blocks";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const SamplesViewer = (props) => {
  // States from props
  const samples = props.samples;

  // Local States
  const [activeTab, setActiveTab] = useState("0");

  // Toggles
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // Helpers
  const langSelector = (lang) => {
    let response;
    switch (lang) {
      case "Shell":
        response = "yaml";
        break;
      /*  case "Csharp":
        response = "csharp";
        break; */
      case "Go":
        response = "go";
        break;
      case "Java":
        response = "java";
        break;
      case "Php":
        response = "php";
        break;
      case "Node":
        response = "javascript";
        break;
      case "Python":
        response = "python";
        break;
      default:
        response = "not_supported";
    }
    return response;
  };

  // RENDER
  return (
    <React.Fragment>
      <h6 className="mt-4">{props.t("Samples")}:</h6>

      {samples && (
        <div className="px-2 code-container">
        <Nav className="nav nav-pills nav-fill mb-3">
          {Object.entries(samples).map((sample, index) => {            
            return (
              <React.Fragment key={`navitem-${index}`}>
                {langSelector(sample[1].lang) !== "not_supported" && (
                  <NavItem
                    className={`nav-item border  border-2 ${
                      activeTab === index.toString() ? "bg-primary text-white border-primary" : "bg-white text-dark"
                    } rounded text-center mx-2 my-1 py-2`}
                    onClick={() => {
                      toggleTab(index.toString());
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="font-weight-bold" style={{ cursor: "pointer" }}>
                      {sample[1].lang}
                    </span>
                  </NavItem>
                )}
              </React.Fragment>
            );
          })}
        </Nav>
        </div>
      )}

      {samples && (
        <TabContent activeTab={activeTab}>
          {Object.entries(samples).map((sample, index) => (
            <React.Fragment key={`tabpane-${index}`}>
              {langSelector(sample[1].lang) !== "not_supported" && (
                <TabPane tabId={index.toString()}>
                  <div className="px-3 code-container">
                    <div className="border rounded bg-light p-3 mb-3">
                      <CopyBlock
                        text={sample[1].source}
                        language={langSelector(sample[1].lang)}
                        showLineNumbers="false"
                        theme={atomOneLight}
                        wrapLongLines
                        customStyle={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </TabPane>
              )}
            </React.Fragment>
          ))}
        </TabContent>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(SamplesViewer);
