// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Container, Row, Col, Nav, NavItem } from "reactstrap";

// router
import { Link } from "react-router-dom";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Dashboard = (props) => {
  // Local States
  const [manualURL, setManualURL] = useState("");

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setManualURL("https://hackmd.io/@iCommunity/HJsvDgEKh");
    } else {
      setManualURL("https://hackmd.io/@iCommunity/HysbvQHYn");
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="dashboard">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Dashboard")}</h3>
              <hr />
              <p>
                <Trans i18nKey="Dashboard01">
                  <a href="https://dashboard.icommunitylabs.com" target="_blank" rel="noreferrer">
                    dashboard
                  </a>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="Dashboard02">
                  <Link to="/dashboard_accesstokens">API</Link>
                  <Link to="/dashboard_webhooks">API</Link>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="Dashboard03">
                  <Link to="/dashboard_signatures">API</Link>
                  <Link to="/dashboard_evidences">API</Link>
                </Trans>
              </p>
              <p>{props.t("Dashboard04")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12">
              <Nav className="nav nav-pills nav-fill mb-3">
                <NavItem className="nav-item bg-white mx-2 my-1">
                  <Link to="/dashboard_accesstokens" className="nav-link">
                    <i className="bx bx-key text-dark font-size-20 mt-4 mb-2"></i>
                    <p className="font-weight-bold text-dark font-size-11 mb-4">{props.t("Dashboard_AccessTokens")}</p>
                  </Link>
                </NavItem>
                <NavItem className="nav-item bg-white mx-2 my-1">
                  <Link to="/dashboard_signatures" className="nav-link">
                    <i className="bx bx-user-plus text-dark font-size-20 mt-4 mb-2"></i>
                    <p className="font-weight-bold text-dark font-size-11 mb-4">{props.t("Dashboard_Signatures")}</p>
                  </Link>
                </NavItem>
                <NavItem className="nav-item bg-white mx-2 my-1">
                  <Link to="/dashboard_evidences" className="nav-link">
                    <i className="bx bx-list-plus text-dark font-size-20 mt-4 mb-2"></i>
                    <p className="font-weight-bold text-dark font-size-11 mb-4">{props.t("Dashboard_Evidences")}</p>
                  </Link>
                </NavItem>
                <NavItem className="nav-item bg-white mx-2 my-1">
                  <Link to="/dashboard_webhooks" className="nav-link">
                    <i className="bx bxs-megaphone text-dark font-size-20 mt-4 mb-2"></i>
                    <p className="font-weight-bold text-dark font-size-11 mb-4">{props.t("Dashboard_Webhooks")}</p>
                  </Link>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <hr />
              <p>
                <Trans i18nKey="Dashboard05">
                  <a href={manualURL} target="_blank" rel="noreferrer">
                    manual
                  </a>
                </Trans>
              </p>
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Dashboard);
