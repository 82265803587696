// React
import React from "react";

// Reactstrap
import { Badge } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const httpContent = (props) => {
  // States from props
  const call = props.call;
  const URL = `https://api.icommunitylabs.com/v2${props.url}`;

  // RENDER
  return (
    <React.Fragment>
      <a name={`${props.url}_${call[0]}`} style={{ position: "relative", top: "-95px" }} />
      <div className="d-flex justify-start align-items-center">
        <Badge pill className={`font-size-9 bg-${call[0]}`}>
          {call[0]}
        </Badge>
        <h5 className="my-0 mx-3">{props.url}</h5>
      </div>
      <p className="font-size-9 text-gray mt-3 mx-2">
        Endpoint URL: <code>{URL}</code>
      </p>
      {/* {(call[1]["description"] || call[1]["description-es"]) && (
        <>
          <h6 className="mt-4 mx-2">{props.t("Description")}:</h6>
          <div className="px-3">
            <span>
              {props.i18n.language === "es"
                ? call[1]["description-es"]
                  ? call[1]["description-es"].split(":\n")[0]
                  : call[1]["description"].split(":\n")[0]
                : call[1]["description"]
                ? call[1]["description"].split(":\n")[0]
                : call[1]["description-es"].split(":\n")[0]}
            </span>
          </div>
        </>
      )} */}
      {(call[1]["tutorial"] || call[1]["tutorial-es"] || call[1]["description"] || call[1]["description-es"]) && (
        <>
          {/* <h6 className="mt-4 mx-2">{props.t("Tutorial")}:</h6> */}
          <div className="px-3">
            <span>
              {props.i18n.language === "es"
                ? call[1]["tutorial-es"]
                  ? call[1]["tutorial-es"]
                  : call[1]["tutorial"]
                  ? call[1]["tutorial"]
                  : call[1]["description-es"]
                  ? call[1]["description-es"].split(":\n")[0]
                  : call[1]["description"].split(":\n")[0]
                : call[1]["tutorial"]
                ? call[1]["tutorial"]
                : call[1]["tutorial-es"]
                ? call[1]["tutorial-es"]
                : call[1]["description"]
                ? call[1]["description"].split(":\n")[0]
                : call[1]["description-es"].split(":\n")[0]}
            </span>
          </div>
        </>
      )}
      {call[1].parameters && (
        <>
          <h6 className="mt-4 mx-2">{props.t("Parameters")}:</h6>
          {Object.entries(call[1].parameters).map((parameter, i) => {
            return (
              <div className="px-3" key={i}>
                <span>{parameter[1].name}:</span>
                <span className="mx-2">
                  {props.i18n.language === "es"
                    ? parameter[1]["description-es"]
                      ? parameter[1]["description-es"]
                      : parameter[1]["description"]
                    : parameter[1]["description"]
                    ? parameter[1]["description"]
                    : parameter[1]["description-es"]}
                </span>

                <span className="font-size-9">
                  {" ("}
                  {parameter[1].schema.type}
                  {")"}
                </span>
              </div>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(httpContent);
