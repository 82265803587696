import React from "react";

const Loader = () => {
  return (
    <div
      className=""
      style={{
        display: "block",
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "9999",
        backgroundColor: "rgba(240,240,240,0.3)",
      }}
    >
      <div
        className=""
        style={{
          position: "absolute",
          width: "10%",
          height: "10%",
          top: "50%",
          left: "45%",
          margin: "auto",
        }}
      >
        <div
          className=""
          style={{
            margin: "auto",
            width: "10%",
            height: "10%",
            position: "relative",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
