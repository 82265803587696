// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const ConceptBlockchain = (props) => {
  return (
    <React.Fragment>
      <Card className="border-0" id="c_blockchain">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("Blockchain")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("Blockchain01")}</p>
          <p>{props.t("Blockchain02")}</p>
          <p>
            <Trans i18nKey={"Blockchain03"}>
              <a href="https://www.avax.network/" target="_blank" rel="noreferrer">
                Avalanche
              </a>
              <a href="https://ethereum.org/" target="_blank" rel="noreferrer">
                Ethereum
              </a>
              <a href="https://fantom.foundation/" target="_blank" rel="noreferrer">
                Fantom
              </a>
              <a href="https://www.gnosis.io/" target="_blank" rel="noreferrer">
                Gnosis
              </a>
              <a href="https://polygon.technology/" target="_blank" rel="noreferrer">
                Polygon
              </a>
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptBlockchain);
