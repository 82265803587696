// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DashboardAccessTokensCreationImg01ES from "assets/images/screenshots/es/access_tokens_01.png";
import DashboardAccessTokensCreationImg01EN from "assets/images/screenshots/en/access_tokens_01.png";
import DashboardAccessTokensCreationImg02ES from "assets/images/screenshots/es/access_tokens_02.png";
import DashboardAccessTokensCreationImg02EN from "assets/images/screenshots/en/access_tokens_02.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DashboardAccessTokensCreation = (props) => {
  // Local States
  const [screenshot01, setScreenshot01] = useState(null);
  const [screenshot02, setScreenshot02] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot01(DashboardAccessTokensCreationImg01ES);
      setScreenshot02(DashboardAccessTokensCreationImg02ES);
    } else {
      setScreenshot01(DashboardAccessTokensCreationImg01EN);
      setScreenshot02(DashboardAccessTokensCreationImg02EN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DashboardAccessTokensCreation")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DashboardAccessTokensCreation01")}</p>
          <p>{props.t("DashboardAccessTokensCreation02")}</p>
          <div className="my-5">
            <ImageZoomer src={screenshot01} alt="" />
          </div>
          <p>{props.t("DashboardAccessTokensCreation03")}</p>
          <ImageZoomer src={screenshot02} alt="" />
          <p>
            <b>{props.t("Attention")}:</b> {props.t("DashboardAccessTokensCreation04")}
          </p>
          <p>{props.t("DashboardAccessTokensCreation05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DashboardAccessTokensCreation);
