// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
/* import ConceptIcom from "components/concepts/ConceptIcom"; */
import ConceptBlockchain from "components/concepts/ConceptBlockchain";
import ConceptChecksum from "components/concepts/ConceptChecksum";
import ConceptEncriptionKey from "components/concepts/ConceptEncriptionKey";
import ConceptEvidence from "components/concepts/ConceptEvidence";
import ConceptIpfs from "components/concepts/ConceptIpfs";
import ConceptSignature from "components/concepts/ConceptSignature";
import ConceptWallet from "components/concepts/ConceptWallet";
import ConceptAccessToken from "components/concepts/Concept-Access_Token";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const Glossary = (props) => {
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="concepts">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Concepts")}</h3>
              <hr />
              <p>{props.t("Concepts01")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            {/*  <Col lg="12" className="my-4">
              <ConceptIcom />
            </Col> */}
            <Col lg="12" className="my-4">
              <ConceptBlockchain />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptChecksum />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptEncriptionKey />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptEvidence />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptIpfs />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptSignature />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptWallet />
            </Col>
            <Col lg="12" className="my-4">
              <ConceptAccessToken />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Glossary);
