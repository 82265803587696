// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const ConceptAccessToken = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="c_blockchain">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("AccessToken")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("AccessToken01")}</p>
          <p>{props.t("AccessToken02")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptAccessToken);
