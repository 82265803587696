// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// React Code Blocks
import { CodeBlock, atomOneLight } from "react-code-blocks";

// React Router Dom
import { Link } from "react-router-dom";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Step03 = (props) => {
  // Local States
  const codeExample = `POST /evidences
  {
    "payload": {
      "title": "May 4th be with you",
      "files": [
        {
          "name": "dstar.dwg",
          "file": "Yt6t3E9363W5WL9..."
        }
      ]
    }
    "signatures": [
      {
        "id": "sig_kqYxYt6t3EfW5WL..."
      }
    ]
  }`;

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("QuickStartStep03")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("QuickStartStep0301")}</p>
          <p>{props.t("QuickStartStep0302")}</p>
          <div className="d-flex justify-content-center my-4">
            <div className="border rounded bg-light p-3">
              <CodeBlock
                text={codeExample}
                language="javascript"
                showLineNumbers="false"
                theme={atomOneLight}
                wrapLongLines
                customStyle={{ width: "100%" }}
              />
            </div>
          </div>
          <p>
            <b>{props.t("Attention")}:</b> {props.t("QuickStartStep0303")}
          </p>
          <p>{props.t("QuickStartStep0304")}</p>
          <p>
            <Trans i18nKey="QuickStartStep0305">
              <a href="https://checker.icommunitylabs.com/" target="_blank" rel="noreferrer">
                checker
              </a>
            </Trans>
          </p>
          <p>
            {props.t("ForMoreInfoPlural")}
            <Trans i18nKey="QuickStartStep0306">
              <Link to="/http-api/2">http_api-evidencias</Link>
              <Link to="/dashboard_evidences">dashboard_evidences</Link>
              <Link to="/checker">checker</Link>
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Step03);
