// React
import React from "react";

// Reactstrap
import { Col, Row, Card, CardBody, Badge } from "reactstrap";

// React Code Blocks
import { CodeBlock, atomOneLight } from "react-code-blocks";

// React Router Dom
import { Link } from "react-router-dom";

// Helpers
import { sortObject } from "helpers/sortObject";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const HttpSecuritySchemasViewer = (props) => {
  // States from props
  const elements = sortObject(props.elements);
  // Helpers
  const codeExempleSelector = (type) => {
    let response;
    switch (type) {
      case "bearer":
        response = "Authorization: Bearer eySsFDd52fsf64s...";
        break;

      default:
        response = "Error: not supported";
    }
    return response;
  };
  const createSelector = (type) => {
    let response;
    switch (type) {
      case "bearer":
        response = (
          <p>
           <Trans i18nKey="SecuritySchemes_Bearer">
              <Link to="/dashboard_accesstokens">dashboard_accesstokens</Link>
            </Trans>
          </p>
        );
        break;

      default:
        response = "Error: not supported";
    }
    return response;
  };
  // RENDER
  return (
    <React.Fragment>
      <Row>
        <Col>
          {Object.entries(elements).map((element, index) => (
            <React.Fragment key={`uno-${index}`}>
              <a name={`${element[0]}`} style={{ position: "relative", top: "-95px" }} />
              <Card className="border-0 mb-4">
                <CardBody className="py-0">
                  <section className="mt-5 pb-2">
                    <div className="d-flex justify-start align-items-center mb-3">
                      <Badge pill className={`font-size-9 bg-primary`}>
                        {element[1].type.toUpperCase()}
                      </Badge>
                      <h5 className="my-0 mx-3">{`${element[1].bearerFormat} ${element[1].scheme}`}</h5>
                    </div>
                    {(element[1]["description"] || element[1]["description-es"]) && (
                      <div  className="mt-4">
                        {/* <h6 className="mt-4 mx-2">{props.t("Description")}:</h6> */}
                        <div className="px-3">
                          <span>
                            {props.i18n.language === "es"
                              ? element[1]["description-es"]
                                ? element[1]["description-es"].split(":\n")[0]
                                : element[1]["description"].split(":\n")[0]
                              : element[1]["description"]
                              ? element[1]["description"].split(":\n")[0]
                              : element[1]["description-es"].split(":\n")[0]}
                          </span>
                        </div>
                      </div>
                    )}
                    {createSelector(element[1].scheme) !== "Error: not supported" && (
                      <div className="px-3">
                        {createSelector(element[1].scheme)}
                      </div>
                      )}
                    {codeExempleSelector(element[1].scheme) !== "Error: not supported" && (
                      <React.Fragment>
                        <h6 className="mt-4 mx-2">{props.t("Example")}:</h6>
                        <div className="px-3 code-container">
                          <div className="my-4 border rounded bg-light p-3">
                            <CodeBlock
                              text={codeExempleSelector(element[1].scheme)}
                              language="javascript"
                              showLineNumbers="false"
                              theme={atomOneLight}
                              wrapLongLines
                              customStyle={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    {/* {element[1].requestHeader && (
                      <div className="mx-2">
                        <h6 className="mt-4">Request Header:</h6>
                        {Object.entries(element[1].requestHeader).map((header, l) => (
                          <div className="px-3 code-container" key={l}>
                            {Object.entries(header[1]).map((bb, m) => (
                              <div className="border rounded bg-light p-3 mb-3" key={m}>
                                <CodeBlock
                                  text={JSON.stringify(bb[1], null, 4)}
                                  language="yaml"
                                  showLineNumbers="false"
                                  theme={atomOneLight}
                                  wrapLongLines
                                  customStyle={{ width: "100%" }}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )} */}

                    <Row>
                      <Col lg="12" className="container-fluid text-end mt-2 mb-3">
                        <a href="#start" className="mx-3">
                          {props.t("ReturnUp")}
                        </a>
                      </Col>
                    </Row>
                  </section>
                </CardBody>
              </Card>
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(HttpSecuritySchemasViewer);
