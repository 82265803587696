// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import RequestDoc from "components/dashboard/DashboardRequestDoc";
import RequestSig from "components/dashboard/DashboardRequestSig";
import RequestPersonalization from "components/dashboard/DashboardRequestPersonalization";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DashboardRequests = (props) => {
    // RENDER
    return (
        <React.Fragment>
            <Container fluid className="viewContainer">
                <section id="dashboard_Webhooks">
                    <Row>
                        <Col lg="12">
                            <h3 className="text-dark pt-4 mb-0">
                                {props.t("Dashboard_Requests")}
                            </h3>
                            <hr />
                            <p>{props.t("Dashboard_Requests01")}</p>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col lg="12" className="my-3">
                            <RequestDoc />
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col lg="12" className="my-3">
                            <RequestSig />
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col lg="12" className="my-3">
                            <RequestPersonalization />
                        </Col>
                    </Row>
                </section>
            </Container>
        </React.Fragment>
    );
};

export default withTranslation()(DashboardRequests);
