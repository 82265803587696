// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// React Router Dom
import { Link } from "react-router-dom";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Home = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="home">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Home")}</h3>
              <hr />
              <p>{props.t("Home01")}</p>
              <p>{props.t("Home02")}</p>
              <p>
                <Trans i18nKey="Home03">
                  <Link to="/dashboard">dashboard</Link>
                  <Link to="/digital_identity">digital_identity</Link>
                  <Link to="/checker">checker</Link>
                  <Link to="/http-api">http-api</Link>
                </Trans>
              </p>
              <p>{props.t("Home04")}</p>
              <p>
                <Trans i18nKey="Home05">
                  <a href="mailto:development@icommunity.io">development</a>
                </Trans>
              </p>
            </Col>
          </Row>
          
       
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Home);
