// React
import React, { useState, useEffect } from "react";

// React Router Dom
import withRouter from "routes/withRouter";
import { Link } from "react-router-dom";

// React Device Detect
import { isMobile } from "react-device-detect";

//i18n
import { withTranslation } from "react-i18next";

// Data
import { MenuData } from "./menuData";

const SidebarContent = (props) => {
  // Local States
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setisopen] = useState({});

  // UseEffects
  useEffect(() => {
    let temp = {};
    Object.keys(MenuData).forEach((key) => {
      let temp2 = [];
      for (let i = 0; i < MenuData[key].length; i++) {
        let hide = true;
        if (MenuData[key][i].submenu.length > 0) {
          for (let j = 0; j < MenuData[key][i].submenu.length; j++) {
            if (
              MenuData[key][i].submenu[j].path ===
              props.router.location.pathname
            ) {
              hide = false;
            }
          }
        }
        if (
          MenuData[key][i].path ===
          props.router.location.pathname
        ) {
          hide = false;
        }
        temp2[i] = hide;
      }
      temp[key] = temp2;
    });
    setisopen(temp);
  }, [props.router.location.pathname]);

  // Toggles
  const toggleopen = (event) => {
    let index = event.target.attributes.index.value;
    let submenuclass = event.target.attributes.submenuclass.value;
    let temp = {};
    Object.keys(MenuData).forEach((key) => {
      let temp2 = [];
      for (let i = 0; i < MenuData[key].length; i++) {
        let hide = true;
        if (MenuData[key][i].submenu.length > 0) {
          for (let j = 0; j < MenuData[key][i].submenu.length; j++) {
            if (key === submenuclass && i === parseInt(index)) {
              hide = !isOpen[key][i];
            }
          }
        }
        temp2[i] = hide;
      }
      temp[key] = temp2;
    });
    setisopen(temp);
    setRefresh(!refresh);
  };

  // MENU
  const Menu = (prps) => {
    const data = prps.data;
    const submenuclass = prps.submenuclass;
    return (
      <>
        <ul className="list-unstyled w-100">
          {data.map((item, index) => {
            return (
              <React.Fragment key={`${submenuclass}-${index}`}>
                {item.submenu.length > 0 ? (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className={`${item.cName} ${
                        item.path === props.router.location.pathname
                          ? "text-menu-active"
                          : "text-menu"
                      } text-decoration-none`}
                      onClick={toggleopen}
                      index={index}
                      submenuclass={submenuclass}
                    >
                      <i
                        className={`${item.icon} ${
                          isMobile && "font-size-18"
                        } icono-menu`}
                        index={index}
                        submenuclass={submenuclass}
                      ></i>
                      {!isMobile && (
                        <label
                          index={index}
                          submenuclass={submenuclass}
                          className="mx-2"
                          style={{ cursor: "pointer" }}
                        >
                          {props.t(item.title)}
                        </label>
                      )}
                      <i
                        className={`bx bx-chevron-${
                          isOpen[submenuclass] && isOpen[submenuclass][index]
                            ? "down"
                            : "up"
                        } text-right`}
                        index={index}
                        submenuclass={submenuclass}
                      ></i>
                    </Link>
                    <ul
                      hidden={
                        isOpen[submenuclass]
                          ? isOpen[submenuclass][index]
                          : true
                      }
                      className="list-unstyled"
                    >
                      {item.submenu.map((item2, index2) => {
                        return (
                          <li key={`${index}-${index2}`}>
                            <Link
                              to={item2.path}
                              className={`${item2.cName} ${
                                item2.path === props.router.location.pathname
                                  ? "text-menu-active"
                                  : "text-menu"
                              } text-decoration-none`}
                            >
                              {isMobile ? (
                                <i
                                  className={`${item2.icon} font-size-16 icono-menu`}
                                ></i>
                              ) : (
                                <label
                                  className="mx-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  {props.t(item2.title)}
                                </label>
                              )}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className={`${item.cName} ${
                        item.path === props.router.location.pathname
                          ? "text-menu-active"
                          : "text-menu"
                      } text-decoration-none`}
                    >
                      <i
                        className={`${item.icon} ${
                          isMobile && "font-size-18"
                        } icono-menu`}
                      ></i>
                      {!isMobile && (
                        <label className="mx-2" style={{ cursor: "pointer" }}>
                          {props.t(item.title)}
                        </label>
                      )}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </>
    );
  };

  // RENDER
  return <Menu data={MenuData.docs} submenuclass="docs" />;
};

export default withRouter(withTranslation()(SidebarContent));
