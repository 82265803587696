// React
import React, {useState, useEffect} from "react";

// Reactstrap
import { Container } from "reactstrap";

// React Router Dom
import AllRoutes from "routes/AllRoutes.jsx";

//Routes
import withRouter from "routes/withRouter";

// React Device Detect
import { isMobile } from "react-device-detect";

// Components
import Header from "components/ui/Header";
import Footer from "components/ui/Footer";
import Logo from "components/ui/Logo";
import PoweredBy from "components/ui/Poweredby";
import Menu from "components/menu/Menu";


// Styles
import "assets/css/mainlayout.css";

function App(props) {
  //Local States
const [background, setBackground]= useState("")
  // UseEffects
  useEffect(() => {   
    console.log("pathname props", props.router.location.pathname)  
    if(props.router.location.pathname==="/"){
      setBackground("homeBackground")
    }else{
      setBackground("")
    }
  },[props.router.location.pathname])

  return (
    <Container className="p-0" fluid>
      <div className="mainlayout-parent">
        <div className="mainlayout-general">
          <div
            className={
              isMobile ? "mainlayout-sidebar-Mobile" : "mainlayout-sidebar"
            }
          >
            <div className="mainlayout-logo">
              <Logo />
            </div>
            <div
              className={
                isMobile ? "mainlayout-menu-mobile" : "mainlayout-menu"
              }
            >
              <Menu />
              
            </div>
             <div className="mainlayout-poweredby">
                  <PoweredBy />
                </div> 
          </div>

          <div className="mainlayout-main">
            <div
              className={
                isMobile ? "mainlayout-header-Mobile" : "mainlayout-header"
              }
            >
              <Header />
            </div>

            <div className={`mainlayout-content ${background}`}>
              <div className="App">
                <AllRoutes />
              </div>
            </div>

            <div className="mainlayout-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default withRouter(App);
