// React
import React from "react";

// Reactstrap
import { Col, Row, Card, CardBody } from "reactstrap";

// Components
import HttpContent from "./httpContent";
/* import HttpRequestBodyViewer from "./HttpRequestBodyViewer"; */
import ExampleViewer from "./ExampleViewer";
import HttpResponses from "./HttpResponsesViewer";
import SamplesViewer from "./SamplesViewer";

// Helpers
import { sortObject } from "helpers/sortObject";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const HttpEndpointViewer = (props) => {
    // States from props
    const elements = sortObject(props.elements);

    // RENDER
    return (
        <React.Fragment>
            <Row>
                <Col>
                    {Object.entries(elements).map((element, index) => (
                        <React.Fragment key={`uno-${index}`}>
                            <a
                                name={`${element[0]}`}
                                style={{ position: "relative", top: "-95px" }}
                            />
                            {Object.entries(element[1]).map((call, j) => {
                                return (
                                    <React.Fragment key={j}>
                                        {call[0] !== "components" &&
                                            !call[(0, 1)].deprecated && (
                                                <Card
                                                    className="border-0 mb-4"
                                                    key={j}>
                                                    <CardBody className="py-0">
                                                        <section className="mt-5 pb-2">
                                                            <HttpContent
                                                                call={call}
                                                                url={element[0]}
                                                            />
                                                            <div className="mx-2">
                                                                {/* {call[(0, 1)].requestBody && <HttpRequestBodyViewer content={call[(0, 1)].requestBody} />} */}
                                                                {call[(0, 1)]
                                                                    .requestBody && (
                                                                    <>
                                                                        {Object.entries(
                                                                            call[
                                                                                (0,
                                                                                1)
                                                                            ]
                                                                                .requestBody
                                                                                .content
                                                                        ).map(
                                                                            (
                                                                                body,
                                                                                l
                                                                            ) => {
                                                                                return (
                                                                                    <React.Fragment
                                                                                        key={
                                                                                            l
                                                                                        }>
                                                                                        {body[1] &&
                                                                                            body[1]
                                                                                                .examples &&
                                                                                            body[1]
                                                                                                .examples
                                                                                                .jsonObject && (
                                                                                                <>
                                                                                                    <h6 className="mt-4">
                                                                                                        {props.t(
                                                                                                            "RequestBodyExample"
                                                                                                        )}

                                                                                                        :
                                                                                                    </h6>
                                                                                                    <ExampleViewer
                                                                                                        element={
                                                                                                            body[1]
                                                                                                                .examples
                                                                                                                .jsonObject
                                                                                                        }
                                                                                                    />
                                                                                                </>
                                                                                            )}
                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </>
                                                                )}

                                                                {call[(0, 1)]
                                                                    .responses && (
                                                                    <HttpResponses
                                                                        content={
                                                                            call[
                                                                                (0,
                                                                                1)
                                                                            ]
                                                                                .responses
                                                                        }
                                                                    />
                                                                )}
                                                                {call[(0, 1)][
                                                                    "x-code-samples"
                                                                ] && (
                                                                    <SamplesViewer
                                                                        samples={
                                                                            call[
                                                                                (0,
                                                                                1)
                                                                            ][
                                                                                "x-code-samples"
                                                                            ]
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            <Row>
                                                                <Col
                                                                    lg="12"
                                                                    className="container-fluid text-end mt-2 mb-3">
                                                                    <a
                                                                        href="#start"
                                                                        className="mx-3">
                                                                        {props.t(
                                                                            "ReturnUp"
                                                                        )}
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </section>
                                                    </CardBody>
                                                </Card>
                                            )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(HttpEndpointViewer);
