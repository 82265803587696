// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody, List } from "reactstrap";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const TrialPeriod = (props) => {
  // Render
  return (
    <React.Fragment>
      <Card className="border-0" id="trial_period">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("TrialPeriod")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("TrialPeriod01")}</p>
          <List>
            <li>
              <Trans i18nKey={"TrialPeriod02"}>
                <a href="mailto:hello@icommunity.io" target="_blank" rel="noreferrer" />
              </Trans>
            </li>
            <li>{props.t("TrialPeriod03")}</li>
            <li>{props.t("TrialPeriod04")}</li>
            <li>{props.t("TrialPeriod05")}</li>
          </List>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(TrialPeriod);
