// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Actions
import { Get_Doc_Info } from "api/docInfoRequest";

// Components
import Loader from "components/ui/Loader";
import EventResponseViewer from "components/api/EventResponseViewer";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const EventResponses = (props) => {
  // Local States
  const [sections, setSections] = useState(null);
  let [isLoading, setIsLoading] = useState(true);

  // UseEffects
  useEffect(() => {
    if (!sections) {
      getDocInfo();
    }
  }, [sections]);

  // Handlers
  const getDocInfo = async () => {
    setIsLoading(true);
    const response = await Get_Doc_Info();
    let data = {};
    Object.entries(response.webhooks).map((call, j) => {
      data[call[0]] = call[1];
    });
    setSections(data);
    setIsLoading(false);
  };

  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="webhooks">
          {isLoading && <Loader />}
          <a name="start" style={{ position: "relative", top: "-95px" }} />
          <Row>
            <Col lg="12" className="mx-2">
              <h3 className="text-dark pt-4 mb-0">{props.t("EventResponses")}</h3>
              <hr />
              <p>{props.t("EventResponses01")}</p>
              <p>{props.t("EventResponses02")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-4">
              {sections && (
                <React.Fragment>
                  {Object.entries(sections).map((element, index) => (
                    <Row className="mb-3" key={`element-${index}`}>
                      <Col lg="12" className="mb-3">
                        <EventResponseViewer elements={element[1]} />
                      </Col>
                    </Row>
                  ))}
                </React.Fragment>
              )}
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(EventResponses);
