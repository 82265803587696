//React
import React from "react";

// Reactstrap
import { Col, Row, Card, CardBody, Badge } from "reactstrap";

// Components
/* import HttpResponses from "./HttpResponsesViewer"; */
/* import HttpRequestBodyViewer from "./HttpRequestBodyViewer"; */
/* import HttpRequestBodyEnumsViewer from "./HttpRequestBodyEnumsViewer"; */
import ExampleViewer from "./ExampleViewer2";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const EventResponseViewer = (props) => {
  // States from props
  const elements = props.elements;

  // RENDER
  return (
    <React.Fragment>
      <Row>
        <Col className="d-flex flex-column">
          {Object.entries(elements).map((call, index) => {
            return (
              <Card className={`border-0  webhook-${index}`} key={index}>
                <CardBody className="py-0">
                  <section className="mt-5 pb-2">
                    {call[0] !== "components" && (
                      <div className="d-flex justify-start align-items-center">
                        <Badge pill className={`font-size-9 bg-${call[0]}`}>
                          {call[1].operationId.split(".")[0]}
                        </Badge>
                        <h5 className="my-0 mx-3">{call[1].summary}</h5>
                      </div>
                    )}
                    {(call[1]["description"] || call[1]["description-es"]) && (
                      <div  className="mt-4">
                        {/* <h6 className="mt-4 mx-2">{props.t("Description")}:</h6> */}
                        <div className="px-3">
                          <span>
                            {props.i18n.language === "es"
                              ? call[1]["description-es"]
                                ? call[1]["description-es"].split(":\n")[0]
                                : call[1]["description"].split(":\n")[0]
                              : call[1]["description"]
                              ? call[1]["description"].split(":\n")[0]
                              : call[1]["description-es"].split(":\n")[0]}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="mx-2">
                      {call[(0, 1)].requestBody && (
                        <>
                          {Object.entries(call[(0, 1)].requestBody.content).map((body, l) => {
                            return (
                              <React.Fragment key={l}>
                                {body[1] && body[1].examples && body[1].examples.jsonObject && (
                                  <>
                                    <h6 className="mt-4">{props.t("PayloadExample")}:</h6>
                                    <ExampleViewer element={body[1].examples.jsonObject} />
                                    {/* <HttpRequestBodyEnumsViewer content={body[1].schema} /> */}
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                      {/* {call[(0, 1)].requestBody && <HttpRequestBodyViewer content={call[(0, 1)].requestBody} />} */}
                      {/*  {call[(0, 1)].responses && <HttpResponses content={call[(0, 1)].responses} />} */}
                    </div>
                    <Row>
                      <Col lg="12" className="container-fluid text-end mt-2 mb-3">
                        <a href="#start" className="mx-3">
                          {props.t("ReturnUp")}
                        </a>
                      </Col>
                    </Row>
                  </section>
                </CardBody>
              </Card>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(EventResponseViewer);
