import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { get, map } from "lodash";

//i18n
import i18n from "i18n";
import languages from "locales/languages";
import { LS_I18N } from "locales/definitions";
import { withTranslation } from "react-i18next";

const LanguageSelector = (props) => {
  // Local States
  const [selectedLang, setSelectedLang] = useState("");

  // UseEffects
  useEffect(() => {
    const currentLanguage = localStorage.getItem(LS_I18N);
    setSelectedLang(currentLanguage);
  }, []);

  // Handlers
  const changeLanguageAction = (event) => {
    i18n.changeLanguage(event.target.getAttribute("data-lang"));
    setSelectedLang(event.target.getAttribute("data-lang"));
  };

  return (
    <Navbar id="navbar" light expand="md">
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar className="navitem">
          <DropdownToggle nav caret className="d-flex align-items-center p-0">
            <img
              src={get(languages, `${selectedLang}.flag`)}
              alt="flag"
              className="mx-2"
              style={{ height: "20px" }}
              key={`${selectedLang}imgSelected`}
            />
            <span data-lang={selectedLang} className="font-size-9">
              {" "}
              {props.t(get(languages, `${selectedLang}.label`))}
            </span>
          </DropdownToggle>
          <DropdownMenu className="mt-3">
            {map(Object.keys(languages), (key) => (
              <DropdownItem
                key={`${key}DropdownItem`}
                data-lang={key}
                onClick={changeLanguageAction}
              >
                <img
                  key={`${key}img`}
                  alt={key}
                  data-lang={key}
                  src={get(languages, `${key}.flag`)}
                  style={{ height: "15px" }}
                />
                <span data-lang={key} className="font-size-8">
                  {" "}
                  {props.t(get(languages, `${key}.label`))}
                </span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};

export default withTranslation()(LanguageSelector);
