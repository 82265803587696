// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import RequestsFaceCheck from "components/requests/RequestsFaceCheck";
import RequestsDoc from "components/requests/RequestsDoc";
import RequestsSig from "components/requests/RequestsSig";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const Requests = (props) => {
    // RENDER
    return (
        <React.Fragment>
            <Container fluid className="viewContainer">
                <section id="checker">
                    <Row>
                        <Col lg="12">
                            <h3 className="text-dark pt-4 mb-0">
                                {props.t("Requests")}
                            </h3>
                            <hr />
                            <p>{props.t("Requests01")}</p>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col lg="12" className="my-3">
                            <RequestsFaceCheck />
                        </Col>
                        <Col lg="12" className="my-3">
                            <RequestsDoc />
                        </Col>
                        <Col lg="12" className="my-3">
                            <RequestsSig />
                        </Col>
                    </Row>
                </section>
            </Container>
        </React.Fragment>
    );
};

export default withTranslation()(Requests);
