// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import DigitalIdentitySelect from "components/digital_identity/DigitalIdentity_Select.jsx";
import DigitalIdentityNeocheck from "components/digital_identity/DigitalIdentity_Neocheck.jsx";
import DigitalIdentityPerson from "components/digital_identity/DigitalIdentity_Person.jsx";
import DigitalIdentityCompany from "components/digital_identity/DigitalIdentity_Company.jsx";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const DigitalIdentity = (props) => {
  // Local States
  const [manualURL, setManualURL] = useState("");

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setManualURL("https://hackmd.io/@iCommunity/r12JqPrK2");
    } else {
      setManualURL("https://hackmd.io/@iCommunity/SkNBLIFY3");
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="digital_identity">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("DigitalIdentity")}</h3>
              <hr />
              <p>{props.t("DigitalIdentity01")}</p>
              <p>{props.t("DigitalIdentity02")}</p>
              <p>{props.t("DigitalIdentity03")}</p>
              <p>
                <Trans i18nKey="DigitalIdentity04">
                  <a href={manualURL} target="_blank" rel="noreferrer">
                    manual
                  </a>
                </Trans>
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <DigitalIdentitySelect />
            </Col>
            <Col lg="12" className="my-3">
              <DigitalIdentityNeocheck />
            </Col>
            <Col lg="12" className="my-3">
              <DigitalIdentityPerson />
            </Col>
            <Col lg="12" className="my-3">
              <DigitalIdentityCompany />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalIdentity);
