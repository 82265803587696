// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DigitalIdentity_PersonImgES from "assets/images/screenshots/es/digitalidentity03.png";
import DigitalIdentity_PersonImgEN from "assets/images/screenshots/es/digitalidentity03.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DigitalIdentity_Person = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(DigitalIdentity_PersonImgES);
    } else {
      setScreenshot(DigitalIdentity_PersonImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DigitalIdentity_Person")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DigitalIdentity_Person01")}</p>
          <ImageZoomer src={screenshot} alt="IBS DigitalIdentity_Person website." />
          <p>{props.t("DigitalIdentity_Person02")}</p>
          <p>{props.t("DigitalIdentity_Person03")}</p>
          <p>{props.t("DigitalIdentity_Person04")}</p>
          <p>{props.t("DigitalIdentity_Person05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalIdentity_Person);
