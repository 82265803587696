import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// images
import SignatureImg from "assets/images/signature_concept@2x.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

const ConceptSignature = (props) => {
  return (
    <React.Fragment>
      <Card className="border-0" id="c_signature">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("Signature")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("Signature01")}</p>
          <p>
            <Trans i18nKey="Signature02">
              Las firmas de personas físicas son creadas a partir de un proceso
              de
              <a
                href="https://en.wikipedia.org/wiki/Know_your_customer"
                target="_blank"
                rel="noreferrer"
              >
                KYC
              </a>
              en el que solicitamos imágenes de un documento de identificación
              válido y una selfie. Una vez validada la identidad, estos archivos
              son encriptados y subidos a <a href="#c_ipfs">IPFS</a>.
            </Trans>
          </p>
          <p>{props.t("Signature03")}</p>
          <div>
          <ImageZoomer
              src={SignatureImg}
              alt=""
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptSignature);
