import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

// i18n
import { withTranslation, Trans } from "react-i18next";

const ConceptChecksum = (props) => {
  return (
    <React.Fragment>
      <Card className="border-0" id="c_checksum">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("Checksum")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("Checksum01")}</p>
          <p>
            <Trans i18nKey="Checksum02">
              El checksum del contenido textual de las evidencias es generado a
              partir del
              <a
                href="https://en.wikipedia.org/wiki/MD5"
                target="_blank"
                rel="noreferrer"
              >
                algoritmo MD5
              </a>
              . Ya el checksum de los archivos es un poco distinto. Primero
              cogemos su contenido y lo codificamos en
              <a
                href="https://en.wikipedia.org/wiki/Base64"
                target="_blank"
                rel="noreferrer"
              >
                base64
              </a>
              y sólo después lo pasamos por el algoritmo MD5.
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptChecksum);
