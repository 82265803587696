// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// React Device Detect
import { isMobile } from "react-device-detect";

// Images
import PoweredByIcommunity from "assets/images/poweredbyicommunity-dark-blue.png";
import LogoIcommunity from "assets/images/isotipo_icommunity_blue.png";

// MAIN
const Logo = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="d-flex justify-content-center align-items-center">
        <Row>
          <Col className="d-flex justify-content-center">
            {isMobile ? (
              <a href="https://icommunity.io/" target="_blank" rel="noreferrer">
                <img
                  className="img-fluid"
                  style={{ maxWidth: "45px", maxHeight: "45px" }}
                  src={LogoIcommunity}
                  alt=""
                />
              </a>
            ) : (
              <a href="https://icommunity.io/" target="_blank" rel="noreferrer">
                <img
                  className="img-fluid"
                  style={{ maxWidth: "180px", maxHeight: "60px" }}
                  src={PoweredByIcommunity}
                  alt=""
                />
              </a>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Logo;
