// React
import React from "react";
import ReactDOM from "react-dom/client";

// React Router Dom
import { BrowserRouter } from "react-router-dom";

// Components
import App from "./App";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "assets/css/colors.css";
import "assets/css/fonts.css";
import "assets/css/custom.css";
import "assets/css/menu.css";

//RENDER
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);
