// R
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DigitalIdentity_CompanyImgES from "assets/images/screenshots/es/digitalidentity04.png";
import DigitalIdentity_CompanyImgEN from "assets/images/screenshots/es/digitalidentity04.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DigitalIdentity_Company = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(DigitalIdentity_CompanyImgES);
    } else {
      setScreenshot(DigitalIdentity_CompanyImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DigitalIdentity_Company")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DigitalIdentity_Company01")}</p>
          <p>{props.t("DigitalIdentity_Company02")}</p>
          <ImageZoomer src={screenshot} alt="IBS DigitalIdentity_Company website." />
          <p>{props.t("DigitalIdentity_Company03")}</p>
          <p>{props.t("DigitalIdentity_Company04")}</p>
          <p>{props.t("DigitalIdentity_Company05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalIdentity_Company);
