// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import CheckerEnterData from "components/checker/CheckerEnterData";
import CheckerCheckData from "components/checker/CheckerCheckData";
import CheckerVerifyData from "components/checker/CheckerVerifyData";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Checker = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="checker">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Checker")}</h3>
              <hr />
              <p>
                <Trans i18nKey={"Checker01"}>
                  <a href="https://dashboard.icommunitylabs.com/registerclient" target="_blank" rel="noreferrer">
                    register
                  </a>
                </Trans>
              </p>
              <p>{props.t("Checker02")}</p>
              <p>{props.t("Checker03")}</p>
              <p>{props.t("Checker04")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <CheckerEnterData />
            </Col>
            <Col lg="12" className="my-3">
              <CheckerCheckData />
            </Col>
            <Col lg="12" className="my-3">
              <CheckerVerifyData />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Checker);
