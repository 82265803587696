// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// React Router Dom
import { Link } from "react-router-dom";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DashboardRequestDocImgES from "assets/images/screenshots/es/DocumentRequestES.png";
import DashboardRequestDocImgEN from "assets/images/screenshots/en/DocumentRequestEN.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const DashboardRequestDoc = (props) => {
    // Local States
    const [screenshot01, setScreenshot01] = useState(null);

    // UseEffects
    useEffect(() => {
        if (props.i18n.language === "es") {
            setScreenshot01(DashboardRequestDocImgES);
        } else {
            setScreenshot01(DashboardRequestDocImgEN);
        }
    }, [props.i18n.language]);

    // RENDER
    return (
        <React.Fragment>
            <Card className="border-0" id="new_evidence">
                <CardHeader className="border-0 bg-white py-3">
                    <h5>{props.t("DashboardRequestDoc")}</h5>
                </CardHeader>
                <CardBody>
                    <p>{props.t("DashboardRequestDoc01")}</p>
                    <ImageZoomer src={screenshot01} alt="" />
                    <p>
                        <Trans i18nKey="DashboardRequestDoc02">
                            <Link to="/requests">requests low code</Link>
                        </Trans>
                    </p>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(DashboardRequestDoc);
