// React
import React from "react";

// React Router Dom
import withRouter from "routes/withRouter";

//components
import MenuContent from "./MenuContent";

// Styles
import "assets/css/menu.css";

//i18n
import { withTranslation } from "react-i18next";

const Menu = (props) => {
  return (
    <React.Fragment>
      <MenuContent />
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Menu));
