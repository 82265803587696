// React
import React from "react";

// Components
import ExampleViewer from "./ExampleViewer";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const HttpResponsesViewer = (props) => {
    // RENDER
    return (
        <React.Fragment>
            <h6 className="mt-4">{props.t("Responses")}:</h6>
            {Object.entries(props.content).map((result, n) => (
                <div className="px-3" key={n}>
                    <p>
                        <span>{result[0]}</span> -{" "}
                        <span>{result[1].description}</span>
                    </p>
                    {result[1].examples && (
                        <ExampleViewer element={result[1].examples} />
                    )}
                    {/*result[1].content &&
                        Object.entries(result[1].content).map((response, m) => {
                            return (
                                <React.Fragment key={m}>
                                    {response[1] &&
                                        response[1].examples &&
                                        response[1].examples.jsonObject && (
                                            <>
                                                <h6 className="mt-4">{props.t("ResponseExample")}:</h6>
                                                <ExampleViewer
                                                    element={
                                                        response[1].examples
                                                            .jsonObject
                                                    }
                                                />
                                            </>
                                        )}
                                </React.Fragment>
                            );
                        })*/}
                </div>
            ))}
        </React.Fragment>
    );
};

export default withTranslation()(HttpResponsesViewer);
