/** Sort content of an object alphabetically */
export const sortObject = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((a, v) => {
      a[v] = obj[v];
      return a;
    }, {});
};

export default sortObject;
