// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DigitalIdentity_NeocheckImgES from "assets/images/screenshots/es/digitalidentity02.png";
import DigitalIdentity_NeocheckImgEN from "assets/images/screenshots/es/digitalidentity02.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DigitalIdentity_Neocheck = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(DigitalIdentity_NeocheckImgES);
    } else {
      setScreenshot(DigitalIdentity_NeocheckImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DigitalIdentity_Neocheck")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DigitalIdentity_Neocheck01")}</p>
          <p>{props.t("DigitalIdentity_Neocheck02")}</p>
          <ImageZoomer src={screenshot} alt="IBS DigitalIdentity_Neocheck website." />
          <p>{props.t("DigitalIdentity_Neocheck03")}</p>
          <p>{props.t("DigitalIdentity_Neocheck04")}</p>
          <p>{props.t("DigitalIdentity_Neocheck05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalIdentity_Neocheck);
