// REACT
import React, { useState, useEffect } from "react";

// REACTSTRAP
import { Card, CardHeader, CardBody } from "reactstrap";

// COMPONENTS
import ImageZoomer from "components/ui/ImageZoomer";

// IMAGES
import RequestsSigImg01ES from "assets/images/screenshots/es/signPasswordES.png";
import RequestsSigImg01EN from "assets/images/screenshots/en/signPasswordEN.png";
import RequestsSigImg02ES from "assets/images/screenshots/es/signReviewES.png";
import RequestsSigImg02EN from "assets/images/screenshots/en/signReviewEN.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const RequestsSig = (props) => {
    // Local States
    const [screenshot01, setScreenshot01] = useState(null);
    const [screenshot02, setScreenshot02] = useState(null);

    // UseEffects
    useEffect(() => {
        if (props.i18n.language === "es") {
            setScreenshot01(RequestsSigImg01ES);
            setScreenshot02(RequestsSigImg02ES);
        } else {
            setScreenshot01(RequestsSigImg01EN);
            setScreenshot02(RequestsSigImg02EN);
        }
    }, [props.i18n.language]);

    // RENDER
    return (
        <React.Fragment>
            <Card className="border-0" id="new_evidence">
                <CardHeader className="border-0 bg-white py-3">
                    <h5>{props.t("RequestsSig")}</h5>
                </CardHeader>
                <CardBody>
                    <p>{props.t("RequestsSig01")}</p>

                    <ImageZoomer
                        className="img-fluid img-center img-700"
                        src={screenshot01}
                        alt="IBS decryption password request."
                    />
                    <p>{props.t("RequestsSig02")}</p>
                    <ImageZoomer
                        className="img-fluid img-center img-700"
                        src={screenshot02}
                        alt="IBS document verification."
                    />
                    <p>{props.t("RequestsSig03")}</p>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(RequestsSig);
