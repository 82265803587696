// React Router Dom
import { Routes, Route } from "react-router-dom";

// PAGES
import Home from "pages/Home";
import FirstSteps from "pages/FirstSteps";
import Acount from "pages/Account";
import QuickStart from "pages/QuickStart";
import Dashboard from "pages/Dashboard";
import DashboardAccessTokens from "pages/Dashboard-Access_Tokens";
import DashboardSignatures from "pages/Dashboard-Signatures";
import DashboardEvidences from "pages/Dashboard-Evidences";
import DashboardWebhooks from "pages/Dashboard-Webhooks";
import DashboardRequests from "pages/Dashboard-Requests";
import DigitalIdentity from "pages/DigitalIdentity";
import Checker from "pages/Checker";
import Requests from "pages/Requests";
import HttpApi from "pages/HttpApi";
import HttpApi2 from "pages/HttpApi2";
import HttpApi3 from "pages/HttpApi3";
import EventResponses from "pages/EventResponses";
import Glossary from "pages/Glossary";
import OtherResources from "pages/OtherResources";

const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/first_steps" element={<FirstSteps />} />
            <Route path="/account" element={<Acount />} />
            <Route path="/quick_start" element={<QuickStart />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
                path="/dashboard_accesstokens"
                element={<DashboardAccessTokens />}
            />
            <Route
                path="/dashboard_signatures"
                element={<DashboardSignatures />}
            />
            <Route
                path="/dashboard_evidences"
                element={<DashboardEvidences />}
            />
            <Route path="/dashboard_webhooks" element={<DashboardWebhooks />} />
            <Route path="/dashboard_requests" element={<DashboardRequests />} />
            <Route path="/digital_identity" element={<DigitalIdentity />} />
            <Route path="/checker" element={<Checker />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/http_api/:tab" element={<HttpApi />} />
            <Route path="/http_api2" element={<HttpApi2 />} />
            <Route path="/http_api3" element={<HttpApi3 />} />
            <Route path="/event_responses" element={<EventResponses />} />
            <Route path="/glossary" element={<Glossary />} />
            <Route path="/other_resources" element={<OtherResources />} />
        </Routes>
    );
};

export default AllRoutes;
