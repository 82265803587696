// React
import React, { useState, useEffect } from "react";

// Axios
import axios from "axios";

// React Code Blocks
import { CodeBlock, atomOneLight } from "react-code-blocks";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const EventResponseExampleViewer = (props) => {
  // States from props
  const element = props.element;

  // Local States
  const [example, setExample] = useState(null);

  // UseEffects
  useEffect(() => {
    getExemple(element.externalValue);
  }, []);

  // Helpers
  const getExemple = async (URL) => {
    let response = await Get_Example(URL);
    setExample(response);
  };
  const Get_Example = async (URL) => {
    const config = {
      method: "get",
      url: URL,
    };

    let response;
    try {
      response = await axios(config);
    } catch (error) {
      console.log("Error gitlab call: ", error);      
      return null
    }
    if (200 <= response.status && response.status < 400) {
      const yaml = require("js-yaml");
      var obj = yaml.load(response.request.responseText, { encoding: "utf-8" });
      return obj;
    } else {
      /* let error = new Error("API response failed: ", response.status, response.statusText);
      throw error; */
      console.log("Error http call: ", response.status);  
      return null
    }
  };
  // RENDER
  return (
    <React.Fragment>
      {/* <h6 className="mt-4">{props.t("PayloadExample")}:</h6> */}
      {example && (
        <>
          <div className="my-3 px-3 code-container">
            {/* <label className="mx-1">{element.summary}</label> */}
            <div className="border rounded bg-light p-3 mb-3">
              <CodeBlock
                text={JSON.stringify(example, null, 4)}
                language="yaml"
                showLineNumbers="false"
                theme={atomOneLight}
                wrapLongLines
                customStyle={{ width: "100%" }}
              />
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EventResponseExampleViewer);