// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

import { CodeBlock, atomOneLight } from "react-code-blocks";

// React Router Dom
import { Link } from "react-router-dom";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import Step01Img01ES from "assets/images/screenshots/es/access_tokens_01.png";
import Step01Img01EN from "assets/images/screenshots/en/access_tokens_01.png";
import Step01Img02ES from "assets/images/screenshots/es/access_tokens_02.png";
import Step01Img02EN from "assets/images/screenshots/en/access_tokens_02.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Step01 = (props) => {
  // Local States
  const [screenshot01, setScreenshot01] = useState(null);
  const [screenshot02, setScreenshot02] = useState(null);
  const codeExample = "Authorization: Bearer <ACCESS_TOKEN>   ";

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot01(Step01Img01ES);
      setScreenshot02(Step01Img02ES);
    } else {
      setScreenshot01(Step01Img01EN);
      setScreenshot02(Step01Img02EN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("QuickStartStep01")}</h5>
        </CardHeader>
        <CardBody>
          <p>
            <Trans i18nKey={"QuickStartStep0101"}>
              <a href="https://dashboard.icommunitylabs.com" target="_blank" rel="noreferrer">
                dashboard
              </a>
            </Trans>
          </p>
          <p>{props.t("QuickStartStep0102")}</p>
          <ImageZoomer src={screenshot01} alt="" />
          <p>{props.t("QuickStartStep0103")}</p>
          <ImageZoomer src={screenshot02} alt="" />
          <p>
            <b>{props.t("Attention")}:</b> {props.t("QuickStartStep0104")}
          </p>
          <p>{props.t("QuickStartStep0105")}</p>
          <div className="d-flex justify-content-center my-4">
            <div className="border rounded bg-light p-3">
              <CodeBlock
                text={codeExample}
                language="javascript"
                showLineNumbers="false"
                theme={atomOneLight}
                wrapLongLines
                customStyle={{ width: "100%" }}
              />
            </div>
          </div>
          <p>
            {props.t("ForMoreInfoPlural")}
            <Trans i18nKey="QuickStartStep0106">
              <Link to="/dashboard_accesstokens">dashboard_accesstokens</Link>
              <Link to="/http_api/0">http_api-securitySchemas</Link>
            </Trans>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Step01);
