import enFlag from "assets/images/flags/en.png";
import esFlag from "assets/images/flags/es.png";
const languages = {
  en: {
    label: "English",
    flag: enFlag,
  },
  es: {
    label: "Spanish",
    flag: esFlag,
  },
};

export default languages;
