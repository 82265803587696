import React from "react";
import { Card, CardHeader, CardBody, List } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// images
import EvidenceImg from "assets/images/evidenceV2@2x.png";

// i18n
import { withTranslation } from "react-i18next";

const ConceptEvidence = (props) => {
  return (
    <React.Fragment>
      <Card className="border-0" id="c_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("Evidence")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("Evidence01")}</p>
          <p>{props.t("Evidence02")}</p>
          <List>
            <li>{props.t("Evidence03")}</li>
            <li>{props.t("Evidence04")}</li>
            <li>{props.t("Evidence05")}</li>
          </List>
          {/* <p>{props.t("Evidence06")}</p> */}
          <div>
          <ImageZoomer
              src={EvidenceImg}
              alt=""
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptEvidence);
