// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// React Router Dom
import { Link } from "react-router-dom";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import Card01Img from "assets/images/signature_concept@2x.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Card01 = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("FirstStepsCard01")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("FirstStepsCard0101")}</p>
          <p>{props.t("FirstStepsCard0102")}</p>
          <div>
            <ImageZoomer src={Card01Img} alt="" />
          </div>
          <p>{props.t("FirstStepsCard0103")}</p>
          <ul>
            <li>
              <Trans i18nKey={"FirstStepsCard0104"}>
                <Link to="/http_api/1">http_api-signatures</Link>
              </Trans>
            </li>
            <li>
              <Trans i18nKey={"FirstStepsCard0105"}>
                <Link to="/dashboard_signatures">dashboard_signatures</Link>
              </Trans>
            </li>
          </ul>
         {/*  <p>
            {props.t("ForMoreInfo")}
            <Trans i18nKey="FirstStepsCard0106">
              <Link to="/glossary">glossary</Link>
            </Trans>
          </p> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Card01);
