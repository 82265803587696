// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DashboardRequestPersonalizationImgES from "assets/images/screenshots/es/customizationES.png";
import DashboardRequestPersonalizationImgEN from "assets/images/screenshots/en/customizationEN.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DashboardRequestPersonalization = (props) => {
    // Local States
    const [screenshot01, setScreenshot01] = useState(null);

    // UseEffects
    useEffect(() => {
        if (props.i18n.language === "es") {
            setScreenshot01(DashboardRequestPersonalizationImgES);
        } else {
            setScreenshot01(DashboardRequestPersonalizationImgEN);
        }
    }, [props.i18n.language]);

    // RENDER
    return (
        <React.Fragment>
            <Card className="border-0" id="new_evidence">
                <CardHeader className="border-0 bg-white py-3">
                    <h5>{props.t("DashboardRequestPersonalization")}</h5>
                </CardHeader>
                <CardBody>
                    <p>{props.t("DashboardRequestPersonalization01")}</p>
                    <ImageZoomer src={screenshot01} alt="" />
                    <p>{props.t("DashboardRequestPersonalization02")}</p>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(DashboardRequestPersonalization);
