import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { LS_I18N } from 'locales/definitions'

import translationES from "locales/es/translation.json"
import translationEN from "locales/en/translation.json"

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

const language = localStorage.getItem(LS_I18N)
if (!language) {
  localStorage.setItem(LS_I18N, "es")
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng:  localStorage.getItem(LS_I18N)  || "es",
    fallbackLng: "es",

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
