// REACT
import React, { useState, useEffect } from "react";

// REACTSTRAP
import { Card, CardHeader, CardBody } from "reactstrap";

// COMPONENTS
import ImageZoomer from "components/ui/ImageZoomer";

// IMAGES
import CheckerCheckData01ImgES from "assets/images/screenshots/es/checker02.png";
import CheckerCheckData01ImgEN from "assets/images/screenshots/en/checker02.png";
import CheckerCheckData02ImgES from "assets/images/screenshots/es/checker03.png";
import CheckerCheckData02ImgEN from "assets/images/screenshots/en/checker03.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const CheckerCheckData = (props) => {
  // Local States
  const [screenshot01, setScreenshot01] = useState(null);
  const [screenshot02, setScreenshot02] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot01(CheckerCheckData01ImgES);
      setScreenshot02(CheckerCheckData02ImgES);
    } else {
      setScreenshot01(CheckerCheckData01ImgEN);
      setScreenshot02(CheckerCheckData02ImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("CheckerCheckData")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("CheckerCheckData01")}</p>
          <p>
            <Trans i18nKey={"CheckerCheckData02"}>
              <a href="https://www.avax.network/" target="_blank" rel="noreferrer">
                Avalanche
              </a>
              <a href="https://ethereum.org/" target="_blank" rel="noreferrer">
                Ethereum
              </a>
              <a href="https://fantom.foundation/" target="_blank" rel="noreferrer">
                Fantom
              </a>
              <a href="https://www.gnosis.io/" target="_blank" rel="noreferrer">
                Gnosis
              </a>
              <a href="https://polygon.technology/" target="_blank" rel="noreferrer">
                Polygon
              </a>
            </Trans>
          </p>
          <ImageZoomer
            className="img-fluid img-center img-700"
            src={screenshot01}
            alt="IBS CheckerCheckDataError website."
          />
          <p>{props.t("CheckerCheckData03")}</p>
          <ImageZoomer
            className="img-fluid img-center img-700"
            src={screenshot02}
            alt="IBS CheckerCheckDataSuccess website."
          />
          <p>
            <Trans i18nKey={"CheckerCheckData04"}>
              <a href="https://dashboard.icommunitylabs.com" target="_blank" rel="noreferrer">
                Dashboard
              </a>
            </Trans>
          </p>
          <p>{props.t("CheckerCheckData05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(CheckerCheckData);
