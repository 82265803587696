// Reactstrap
import { Container, Row, Col, Label } from "reactstrap";

//package.json
const version = require("../../../package.json").version;

const Footer = (props) => {
  // Render
  return (
    <footer className="footer">
      <Container className="container-fluid d-sm-block">
        <Row>
          <Col className="d-flex justify-content-center">
            <div className="text-center">
              <Label className="font-size-8 text-secondary" style={{ margin: "0px 8px" }}>
                © 2019-{new Date().getFullYear()} iCommunity Labs & Tech S.L.
              </Label>
              {props.centered ? (
                <br />
              ) : (
                <Label className="font-size-8 text-secondary" style={{ margin: "0px 8px" }}>
                  -
                </Label>
              )}

              <Label className="font-size-8 text-secondary" style={{ margin: "0px 8px" }}>
                IBS Documentation v.{version}
              </Label>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
