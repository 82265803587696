import axios from "axios";

const Get_Doc_Info = async () => {
  // Make a request for the documentation file
  const config = {
    method: "get",
    url: "https://gitlab.com/api/v4/projects/29308372/repository/files/v2%2Fbuild%2Fv2-with-examples.yaml/raw?ref=prod",
  };

  let response;
  try {
    response = await axios(config);
  } catch (error) {
    console.log("Error gitlab call: ", error);
  }

  if (200 <= response.status && response.status < 400) {
    const yaml = require("js-yaml");
    var swaggerDoc = yaml.load(response.request.responseText, { encoding: "utf-8" });
    /* console.log("swaggerDoc",swaggerDoc); */
    return swaggerDoc;
  } else {
    let error = new Error("API response failed: ", response.status, response.statusText);
    throw error;
  }
};

export { Get_Doc_Info };
