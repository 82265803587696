// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import Signatures from "components/dashboard/DashboardSignaturesCreation";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DashboardSignatures = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="dashboard_Signatures">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Dashboard_Signatures")}</h3>
              <hr />
              <p>{props.t("Dashboard_Signatures01")}</p>
              <p>{props.t("Dashboard_Signatures02")}</p>
              <p>{props.t("Dashboard_Signatures03")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <Signatures />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(DashboardSignatures);
