// React
import React, { useState } from "react";

// Reactstrap
import { Modal, ModalBody } from "reactstrap";

// MODAL
const ZoomModal = (props) => {
  // Functions from props
  const { toggle } = props;

  // States from props
  const { isOpen, src, alt } = props;

  // RENDER
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={toggle}
      size="xl"
      fullscreen={true}
    >
      <div className="modal-content">
        <ModalBody
          className="d-flex justify-content-center align-items-center bg-white"
          onClick={toggle}
          style={{ cursor: "zoom-out" }}
        >
          <img className="img-fluid img-center w-75" src={src} alt={alt} />
        </ModalBody>
      </div>
    </Modal>
  );
};

// MAIN
const ImageZoomer = (props) => {
  // Local States
  const [zoom, setZoom] = useState(false);

  // Togglles
  const toggleZoom = () => {
    setZoom(!zoom);
  };

  // RENDER
  return (
    <div className="my-5">
      <img
        className="img-fluid img-center img-700"
        src={props.src}
        alt={props.alt}
        onClick={toggleZoom}
        style={{ cursor: "zoom-in" }}
      />
      {zoom && <ZoomModal isOpen={zoom} toggle={toggleZoom} src={props.src} alt={props.alt} />}
    </div>
  );
};

export default ImageZoomer;
