// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Card, CardHeader, CardBody } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import DigitalIdentity_SelectImgES from "assets/images/screenshots/es/digitalidentity01.png";
import DigitalIdentity_SelectImgEN from "assets/images/screenshots/es/digitalidentity01.png";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DigitalIdentity_Select = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(DigitalIdentity_SelectImgES);
    } else {
      setScreenshot(DigitalIdentity_SelectImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("DigitalIdentity_Select")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("DigitalIdentity_Select01")}</p>
          <p>{props.t("DigitalIdentity_Select02")}</p>
          <ImageZoomer src={screenshot} alt="IBS DigitalIdentity_Select website." />
          <p>{props.t("DigitalIdentity_Select03")}</p>
          <p>
            <b>{props.t("Attention")}:</b> {props.t("DigitalIdentity_Select04")}
          </p>
          <p>{props.t("DigitalIdentity_Select05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalIdentity_Select);
