// React
import React from "react";

// Reactstrap
import { Card, CardHeader, CardBody, List } from "reactstrap";

// Components
import ImageZoomer from "components/ui/ImageZoomer";

// Images
import Card02Img from "assets/images/evidence_concept@2x.png";

// React Router Dom
import { Link } from "react-router-dom";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Card02 = (props) => {
  // Render
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("FirstStepsCard02")}</h5>
        </CardHeader>
        <CardBody>
        <p>{props.t("FirstStepsCard0201")}</p>
          <p>{props.t("FirstStepsCard0202")}</p>           
          <List>
            <li>{props.t("Evidence03")}</li>
            <li>{props.t("Evidence04")}</li>
            <li>{props.t("Evidence05")}</li>
          </List>       
          <div>
          <ImageZoomer
              src={Card02Img}
              alt=""
            />
          </div>
          <p>{props.t("FirstStepsCard0203")}</p>
          <ul>
            <li> <Trans i18nKey={"FirstStepsCard0204"}>
            <Link to="/http_api/2">http_api-Evidences</Link>
            </Trans></li>
            <li> <Trans i18nKey={"FirstStepsCard0205"}>
            <Link to="/dashboard_evidences">dashboard_evidences</Link>
            </Trans></li>
          </ul>
         {/*  <p>
            {props.t("ForMoreInfo")}
            <Trans i18nKey="FirstStepsCard0206">
              <Link to="/glossary">glossary</Link>
            </Trans>
          </p> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Card02);
