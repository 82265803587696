import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

// i18n
import { withTranslation, Trans } from "react-i18next";

const ConceptEncriptionKey = (props) => {
  return (
    <React.Fragment>
      <Card className="border-0" id="c_encrip_key">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("EncripKey")}</h5>
        </CardHeader>
        <CardBody>
          <p>
            <Trans i18nKey="EncripKey01">
              Solicitamos una contraseña en el momento de la creación de la
              firma con el único propósito de encriptar los archivos de la
              identidad antes de almacenarlos en <a href="#c_ipfs">IPFS</a>.
              Esta contraseña es de
              <strong>única responsabilidad del firmante</strong> y no la
              guardamos en ningún momento, por tanto no se puede recuperar o
              cambiar.
            </Trans>
          </p>
          <p>{props.t("EncripKey02")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(ConceptEncriptionKey);
