// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Actions
import { Get_Doc_Info } from "api/docInfoRequest";

// Components
import Loader from "components/ui/Loader";
import HttpEndpointViewer from "components/api/HttpEndpointViewer";
import HttpSecuritySchemasViewer from "components/api/HttpSecuritySchemasViewer";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const HttpApi = (props) => {
  // Local States
  const [activeTab, setActiveTab] = useState("0");
  const [sections, setSections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(true);

  // UseEffects
  useEffect(() => {
    if (!sections) {
      getDocInfo();
    }
  }, [sections]);

  // Handlers
  const getDocInfo = async () => {
    setIsLoading(true);
    const response = await Get_Doc_Info();
    let data = {};
    Object.entries(response.components).map((call, j) => {
      data[call[0]] = call[1];
    });
    Object.entries(response.paths).map((call, j) => {
      let parts = call[0].split("/");
      if (!data[parts[1]]) {
        data[parts[1]] = {};
      }
      if (parts.length > 2) {
        data[parts[1]][call[0]] = call[1];
      } else {
        data[parts[1]][call[0]] = call[1];
      }
    });
    setSections(data);
    setIsLoading(false);
  };

  // Toggles
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="http_api">
          {isLoading && <Loader />}
          <a name="start" style={{ position: "relative", top: "-95px" }} />
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("HttpApi3")}</h3>
              <hr />
              <p>{props.t("HttpApi01")}</p>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {sections && (
                <Nav className="nav nav-pills nav-fill mb-3">
                  {Object.entries(sections).map((element, index) => (
                    <NavItem
                      className={`nav-item border  border-2 ${
                        activeTab === index.toString() ? "bg-primary text-white border-primary" : "bg-white text-dark"
                      } rounded text-center mx-2 my-1 py-2`}
                      onClick={() => {
                        toggleTab(index.toString());
                      }}
                      style={{ cursor: "pointer" }}
                      key={`navitem-${index}`}
                    >
                      <span className="font-weight-bold" style={{ cursor: "pointer" }}>
                        {props.t(element[0])}
                      </span>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {sections && (
                <TabContent activeTab={activeTab}>
                  {Object.entries(sections).map((element, index) => (
                    <React.Fragment key={`tabpane-${index}`}>
                      {element[0] === "securitySchemes" ? (
                        <TabPane tabId={index.toString()}>
                          {activeTab === index.toString() && <HttpSecuritySchemasViewer elements={element[1]} />}
                          {showMenu ? (
                            <div
                              style={{ width: "300px", position: "fixed", right: "50px", top: "150px" }}
                              className="border bg-white"
                            >
                              <i
                                className="bx bx-x-circle text-dark font-size-18"
                                onClick={toggleShowMenu}
                                style={{ cursor: "pointer", position: "absolute", right: "5px", top: "5px" }}
                              ></i>
                              <Row>
                                <Col lg="12" className="my-4">
                                  {sections && (
                                    <Nav vertical className="mb-3">
                                      <NavItem>
                                        <NavLink
                                          href={`#start`}
                                          className="text-dark text-decorartion-none d-flex align-items-center"
                                        >
                                          <i className="bx bx-atom font-size-5"></i>
                                          <span className="mx-1">{props.t(element[0])}</span>
                                        </NavLink>
                                      </NavItem>
                                      <Nav vertical className="mx-4">
                                        {Object.entries(element[1]).map((securitySchema, j) => {
                                          return (
                                            <React.Fragment key={`securitySchema-${j}`}>
                                              <NavItem>
                                                <NavLink
                                                  href={`#${securitySchema[0]}`}
                                                  className="text-dark text-decorartion-none d-flex align-items-center"
                                                >
                                                  <i className="bx bx-radio-circle-marked font-size-6"></i>
                                                  <span className="mx-1">{props.t(securitySchema[0])}</span>
                                                </NavLink>
                                              </NavItem>
                                            </React.Fragment>
                                          );
                                        })}
                                      </Nav>
                                      <NavItem
                                        className="border text-center mx-3 mt-3"
                                        onClick={() => {
                                          setShowMenu(false);
                                        }}
                                      >
                                        <NavLink
                                          href="#start"
                                          className="text-dark text-decorartion-none d-flex justify-content-center align-items-center"
                                        >
                                          <i className="bx bx-up-arrow-alt"></i>
                                          <span className="mx-1">{props.t("ReturnUp")}</span>
                                          <i className="bx bx-up-arrow-alt"></i>
                                        </NavLink>
                                      </NavItem>
                                    </Nav>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div style={{ position: "fixed", right: "55px", top: "155px" }}>
                              <i
                                className="bx bx-chevron-left-circle text-dark font-size-18 bg-white"
                                title="ViewMenu"
                                onClick={toggleShowMenu}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          )}
                        </TabPane>
                      ) : (
                        <TabPane tabId={index.toString()}>
                          {activeTab === index.toString() && <HttpEndpointViewer elements={element[1]} />}
                          {showMenu ? (
                            <div
                              style={{ width: "300px", position: "fixed", right: "50px", top: "150px" }}
                              className="border bg-white"
                            >
                              <i
                                className="bx bx-x-circle text-dark font-size-18"
                                onClick={toggleShowMenu}
                                style={{ cursor: "pointer", position: "absolute", right: "5px", top: "5px" }}
                              ></i>
                              <Row>
                                <Col lg="12" className="my-4">
                                  {sections && (
                                    <Nav vertical className="mb-3">
                                      <NavItem>
                                        <NavLink
                                          href={`#start`}
                                          className="text-dark text-decorartion-none d-flex align-items-center"
                                        >
                                          <i className="bx bx-atom font-size-5"></i>
                                          <span className="mx-1">{props.t(element[0])}</span>
                                        </NavLink>
                                      </NavItem>
                                      <Nav vertical className="mx-4">
                                        {Object.entries(element[1]).map((endpoint, j) => {
                                          return (
                                            <React.Fragment key={`endpoint-${j}`}>
                                              <NavItem>
                                                <NavLink
                                                  href={`#${endpoint[0]}`}
                                                  className="text-dark text-decorartion-none d-flex align-items-center"
                                                >
                                                  <i className="bx bx-radio-circle-marked font-size-6"></i>
                                                  <span className="mx-1">{props.t(endpoint[0])}</span>
                                                </NavLink>
                                              </NavItem>
                                              <Nav vertical className="mx-4">
                                                {Object.entries(endpoint[1]).map((call, k) => (
                                                  <React.Fragment key={`call-${k}`}>
                                                    {call[0] !== "components" && !call[(0, 1)].deprecated && (
                                                      <NavItem>
                                                        <NavLink
                                                          href={`#${endpoint[0]}_${call[0]}`}
                                                          className="text-dark text-decorartion-none d-flex align-items-center"
                                                        >
                                                          <i className="bx bx-radio-circle font-size-5"></i>
                                                          <span className="mx-1">{props.t(call[0])}</span>
                                                        </NavLink>
                                                      </NavItem>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </Nav>
                                            </React.Fragment>
                                          );
                                        })}
                                      </Nav>
                                      <NavItem
                                        className="border text-center mx-3 mt-3"
                                        onClick={() => {
                                          setShowMenu(false);
                                        }}
                                      >
                                        <NavLink
                                          href="#start"
                                          className="text-dark text-decorartion-none d-flex justify-content-center align-items-center"
                                        >
                                          <i className="bx bx-up-arrow-alt"></i>
                                          <span className="mx-1">{props.t("ReturnUp")}</span>
                                          <i className="bx bx-up-arrow-alt"></i>
                                        </NavLink>
                                      </NavItem>
                                    </Nav>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div style={{ position: "fixed", right: "55px", top: "155px" }}>
                              <i
                                className="bx bx-chevron-left-circle text-dark font-size-18 bg-white"
                                title="ViewMenu"
                                onClick={toggleShowMenu}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          )}
                        </TabPane>
                      )}
                    </React.Fragment>
                  ))}
                </TabContent>
              )}
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(HttpApi);
