// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import Card01 from "components/firststeps/Card01";
import Card02 from "components/firststeps/Card02";
/* import Card03 from "components/firststeps/Card03"; */

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const FirstSteps = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="first_steps">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("FirstSteps")}</h3>
              <hr />
              <p>{props.t("FirstSteps01")}</p>
              <p>{props.t("FirstSteps02")}</p>
              <p>{props.t("FirstSteps03")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <Card01 />
            </Col>
            <Col lg="12" className="my-3">
              <Card02 />
            </Col>
           {/*  <Col lg="12" className="my-3">
              <Card03 />
            </Col> */}
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(FirstSteps);
