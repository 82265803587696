// REACT
import React, { useState, useEffect } from "react";

// REACTSTRAP
import { Card, CardHeader, CardBody } from "reactstrap";

// COMPONENTS
import ImageZoomer from "components/ui/ImageZoomer";

// IMAGES
import RegistrationImgES from "assets/images/screenshots/es/registration.png";
import RegistrationImgEN from "assets/images/screenshots/en/registration.png";

// i18n
import { withTranslation, Trans } from "react-i18next";

// MAIN
const Registration = (props) => {
  // Local States
  const [screenshot, setScreenshot] = useState(null);

  // UseEffects
  useEffect(() => {
    if (props.i18n.language === "es") {
      setScreenshot(RegistrationImgES);
    } else {
      setScreenshot(RegistrationImgEN);
    }
  }, [props.i18n.language]);

  // RENDER
  return (
    <React.Fragment>
      <Card className="border-0" id="new_evidence">
        <CardHeader className="border-0 bg-white py-3">
          <h5>{props.t("Registration")}</h5>
        </CardHeader>
        <CardBody>
          <p>{props.t("Registration01")}</p>
          <p>
            <Trans i18nKey={"Registration02"}>
              <a href="https://dashboard.icommunitylabs.com/registerclient" target="_blank" rel="noreferrer">
                newclient
              </a>
            </Trans>
          </p>
          <div className="my-5">
            <ImageZoomer src={screenshot} alt="IBS Registration website." />
          </div>
          <p>{props.t("Registration03")}</p>
          <p>
            <Trans i18nKey={"Registration04"}>
              <a href="https://dashboard.icommunitylabs.com" target="_blank" rel="noreferrer">
                dashboard
              </a>
            </Trans>
          </p>
          <p>{props.t("Registration05")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(Registration);
