// React
import React from "react";

// Reactstrap
import { Container, Row, Col } from "reactstrap";

// Components
import Webhooks from "components/dashboard/DashboardWebhooksCreation";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const DashboardWebhooks = (props) => {
  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="dashboard_Webhooks">
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("Dashboard_Webhooks")}</h3>
              <hr />
              <p>{props.t("Dashboard_Webhooks01")}</p>
              <p>{props.t("Dashboard_Webhooks02")}</p>
              <p>{props.t("Dashboard_Webhooks03")}</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12" className="my-3">
              <Webhooks />
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(DashboardWebhooks);
