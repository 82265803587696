// React
import React from "react";

// Reacstrap
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// React Device Detect
import { isMobile } from "react-device-detect";

// Components
import LanguageSelector from "./LanguageSelector";

//i18n
import { withTranslation } from "react-i18next";

const Header = (props) => {
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  // RENDER
  return (
    <Container fluid>
      <Row data-tour="header_options">
        <Col sm="11" lg="11" className={`d-flex justify-content-${isMobile ? "start" : "end"}`}>
          <LanguageSelector />
        </Col>
        {!isMobile && (
          <Col sm="1" lg="1" className="d-flex justify-content-start align-items-center">
            <UncontrolledTooltip
              placement="bottom-end"
              target={"tt-fullscreen"}
              style={{ backgroundColor: "white", color: "black", border: "1px solid black" }}
            >
              {props.t("FullScreen")}
            </UncontrolledTooltip>
            <i
              id={"tt-fullscreen"}
              className="bx bx-fullscreen text-muted font-size-12 mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                toggleFullscreen();
              }}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default withTranslation()(Header);
