// React
import React, { useState, useEffect } from "react";

// Reactstrap
import { Container, Row, Col, Nav, NavItem, TabContent, TabPane } from "reactstrap";

// React Router Dom
import withRouter from "routes/withRouter";

// Actions
import { Get_Doc_Info } from "api/docInfoRequest";

// Components
import Loader from "components/ui/Loader";
import HttpEndpointViewer from "components/api/HttpEndpointViewer";
import HttpSecuritySchemasViewer from "components/api/HttpSecuritySchemasViewer";

// i18n
import { withTranslation } from "react-i18next";

// MAIN
const HttpApi = (props) => {
  // Local States
  const [activeTab, setActiveTab] = useState("0");
  const [sections, setSections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // UseEffects
  useEffect(() => {
    if (!sections) {
      getDocInfo();
    }
  }, [sections]);

  useEffect(() => {
    setActiveTab((props.router.params.tab).toString())
  }, [props.router.location.pathname]);

  // Handlers
  const getDocInfo = async () => {
    setIsLoading(true);
    const response = await Get_Doc_Info();
    let data = {};
    Object.entries(response.components).map((call, j) => {
      data[call[0]] = call[1];
    });
    Object.entries(response.paths).map((call, j) => {
      let parts = call[0].split("/");
      if (!data[parts[1]]) {
        data[parts[1]] = {};
      }
      if (parts.length > 2) {
        data[parts[1]][call[0]] = call[1];
      } else {
        data[parts[1]][call[0]] = call[1];
      }
    });
    setSections(data);
    setIsLoading(false);
  };

  // Toggles
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // RENDER
  return (
    <React.Fragment>
      <Container fluid className="viewContainer">
        <section id="http_api">
          {isLoading && <Loader />}
          <a name="start" style={{ position: "relative", top: "-95px" }} />
          <Row>
            <Col lg="12">
              <h3 className="text-dark pt-4 mb-0">{props.t("HttpApi")}</h3>
              <hr />
              <p>{props.t("HttpApi01")}</p>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {sections && (
                <Nav className="nav nav-pills nav-fill mb-3">
                  {Object.entries(sections).map((element, index) => (
                    <NavItem
                      className={`nav-item border  border-2 ${
                        activeTab === index.toString() ? "bg-primary text-white border-primary" : "bg-white text-dark"
                      } rounded text-center mx-2 my-1 py-2`}
                      onClick={() => {
                        toggleTab(index.toString());
                      }}
                      style={{ cursor: "pointer" }}
                      key={`navitem-${index}`}
                    >
                      <span className="font-weight-bold" style={{ cursor: "pointer" }}>
                        {props.t(element[0])}
                      </span>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {sections && (
                <TabContent activeTab={activeTab}>
                  {Object.entries(sections).map((element, index) => (
                    <React.Fragment key={`tabpane-${index}`}>
                      {element[0] === "securitySchemes" ? (
                        <TabPane tabId={index.toString()}>
                          {activeTab === index.toString() && <HttpSecuritySchemasViewer elements={element[1]} />}
                        </TabPane>
                      ) : (
                        <TabPane tabId={index.toString()}>
                          {activeTab === index.toString() && <HttpEndpointViewer elements={element[1]} />}
                        </TabPane>
                      )}
                    </React.Fragment>
                  ))}
                </TabContent>
              )}
            </Col>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(HttpApi));
