export const MenuData = {
    docs: [
        {
            title: "HomeMenu",
            path: "/",
            icon: "bx bx-home-circle",
            submenu: [],
        },
        {
            title: "GetStarted",
            path: "#",
            icon: "bx bx-edit-alt",
            cName: "has-arrow",
            submenu: [
                {
                    title: "Account",
                    path: "/account",
                    icon: "bx bx-user",
                    cName: "submenu",
                },
                {
                    title: "QuickStart",
                    path: "/first_steps",
                    icon: "bx bx-map-pin",
                    cName: "submenu",
                },
            ],
        },
        {
            title: "Dashboard",
            path: "/dashboard",
            icon: "bx bx-bar-chart-square",
            cName: "has-arrow",
            submenu: [
                {
                    title: "Dashboard_AccessTokens",
                    path: "/dashboard_accesstokens",
                    icon: "bx bx-user-check",
                    cName: "submenu",
                },
                {
                    title: "Dashboard_Signatures",
                    path: "/dashboard_signatures",
                    icon: "bx bx-code-alt",
                    cName: "submenu",
                },
                {
                    title: "Dashboard_Evidences",
                    path: "/dashboard_evidences",
                    icon: "bx bx-code-alt",
                    cName: "submenu",
                },
                {
                    title: "Dashboard_Requests",
                    path: "/dashboard_requests",
                    icon: "bx bx-transfer-alt",
                    cName: "submenu",
                },
                {
                    title: "Dashboard_Webhooks",
                    path: "/dashboard_webhooks",
                    icon: "bx bx-transfer-alt",
                    cName: "submenu",
                },
            ],
        },
        {
            title: "Low_Code_Tools",
            path: "#",
            icon: "bx bx-wrench",
            cName: "has-arrow",
            submenu: [
                {
                    title: "DigitalIdentity",
                    path: "/digital_identity",
                    icon: "bx bx-id-card",
                    cName: "submenu",
                },
                {
                    title: "Requests",
                    path: "/requests",
                    icon: "bx bx-paper-plane",
                    cName: "submenu",
                },
            ],
        },

        {
            title: "Checker",
            path: "/checker",
            icon: "bx bx-check-shield",
            submenu: [],
        },

        {
            title: "RestAPI",
            path: "#",
            icon: "bx bx-code-alt",
            cName: "has-arrow",
            submenu: [
                {
                    title: "HttpApi",
                    path: "/http_api/0",
                    icon: "bx bx-code-alt",
                    cName: "submenu",
                },
                /* {
          title: "HttpApi2",
          path: "/http_api2",
          icon: "bx bx-code-alt",
          cName: "submenu",
        }, */
                /* {
          title: "HttpApi3",
          path: "/http_api3",
          icon: "bx bx-code-alt",
          cName: "submenu",
        }, */
                {
                    title: "EventResponses",
                    path: "/event_responses",
                    icon: "bx bx-transfer-alt",
                    cName: "submenu",
                },
            ],
        },
        /* {
      title: "Glosary",
      path: "/glossary",
      icon: "bx bx-list-ul",
      submenu: [],
    }, */
        {
            title: "OtherResources",
            path: "/other_resources",
            icon: "bx bx-search",
            submenu: [],
        },
    ],
};
